import React from 'react'
import { styled } from '@material-ui/core/styles'
import Button from '../components/basic/Button'
import Container from '../components/basic/Container'
import MainLayout from '../components/MainLayout'
import LevelSlider from '../components/complex/LevelSlider'
import QuestionContainer from '../components/QuestionContainer'

const CenteredContainer = styled(Container)({
  textAlign: 'center',
  marginBottom: '5rem'
})

const marks = [
  {
    value: -5,
    label: 'N5 (leicht)',
  },
  {
    value: -4,
    label: 'N4',
  },
  {
    value: -3,
    label: 'N3',
  },
  {
    value: -2,
    label: 'N2',
  },
  {
    value: -1,
    label: 'N1 (schwer)',
  },
];

const LevelSelector = ({onStart}: {onStart: (min: number, max: number) => void}) => {
  const [level, setLevel] = React.useState<number[]>([-5, -4]);

  const handleLevelChange = (event: any, newValue: number | number[]) => {
    setLevel(newValue as number[]);
  };

  const levelFormat = (value: number) => {
    return `N${-value}`;
  }

  const levelText = level[0] === level[1] ? `JLPT N${-level[0]} Kanji` :
    `JLPT N${-level[0]} bis N${-level[1]} Kanji`

  const handleStart = () => {
    onStart(level[0], level[1]);
  }

  return (
    <CenteredContainer>
      <h2>Kanji lesen</h2>  
      <p>Wähle deine Stufe. Du kannst Start und Ende verändern.</p>

      <LevelSlider
        value={level}
        onChange={handleLevelChange}
        valueLabelDisplay="auto"
        min={-5}
        max={-1}
        marks={marks}
        valueLabelFormat={levelFormat} />

      <p>Aktuelle Auswahl: {levelText}</p>
      <p><Button onClick={handleStart}>Los!</Button></p>
    </CenteredContainer>
  )
}

const IndexPage = () => {
  const [level, setLevel] = React.useState<string>(null);

  const handleStart = (min: number, max: number) => {
    const lists = [];
    for (let i: number = min; i <= max; i++) {
      lists.push(`n${-i}`);
    }
    setLevel(lists.join(','));
  }

  return (
    <MainLayout>
      { level ? <QuestionContainer questionType="0" list={level} /> : <LevelSelector onStart={handleStart}/> }
    </MainLayout>
  )
}

export default IndexPage
